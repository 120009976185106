import { ReactComponent as CloseCircleOutline } from 'assets/images/circle-delete.svg';
import Button from "lib/components/button/Button";
import Modal from "lib/components/modal/Modal";
import NamedAvatar from "lib/components/named-avatar/NamedAvatar";
import Pagination from "lib/components/pagination/Pagination";
import SearchInput from "lib/components/search-input/SearchInput";
import Table from "lib/components/table/Table";
import { reduceLocation } from "lib/dataTransform";
import debounce from 'lodash.debounce';
import usersService from "modules/users/usersService";
import { useEffect, useState } from 'react';

const inspectorsTableColumns = [
  {
    title: 'Name / Company',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => (
      <>
        <NamedAvatar user={record} />
      </>
    ),
  },
  {
    title: 'Location',
    sortId: 'fullAddress',
    render: (record) => reduceLocation(record, 'city', 'country'),
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const UserSelectorBlock = ({
  selectedUsers,
  onChangeSelectedUsers,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userListParams, setUserListParams] = useState({
    sortBy: 'id',
    sortOrder: 'ASC',
    search: '',
    page: 1,
  })

  useEffect(() => {
    const getAvailableOwners = debounce(
      (params) => {
        return usersService
          .getUsers(params)
          .then((res) => {
            setUserList(res?.data?.length ? res.data : []);
            setUserCount(res?.count > 0 ? res.count : 0);
          })
          .catch((e) => {
            console.log('An error occured while fetching users', e);
          });
      },
      400,
      { leading: true },
    );

    if (isModalOpen) {
      getAvailableOwners({
        order: { [userListParams.sortBy]: userListParams.sortOrder },
        filters: { status: 'active' },
        search: userListParams.search,
        page: userListParams.page,
        pageSize: 10,
      });
    }
  }, [
    JSON.stringify(userListParams),
    isModalOpen
  ]);

  const doCloseModal = () => {
    setIsModalOpen(false);
    setUserList([]);
    setUserCount(0);
    setUserListParams(
      {
        sortBy: 'id',
        sortOrder: 'ASC',
        search: '',
        page: 1,
      }
    );
  }

  const doSelectUser = (u) => {
    if (!selectedUsers?.some(uSel => uSel?.id === u?.id)) {
      onChangeSelectedUsers(selectedUsers?.length ? [...selectedUsers, u] : [u]);
    }
    doCloseModal();
  }

  const doUnselectUser = (u) => {
    onChangeSelectedUsers(selectedUsers?.filter(uSel => uSel?.id !== u?.id) ?? []);
    doCloseModal();
  }

  return (
    <div
      className='user-selector-block'
    >
      {!!selectedUsers?.length && (
        <div className='users-list'>
          {selectedUsers.map((user) => (
            <div className='user' key={`user-${user.id}`}>
              <NamedAvatar user={user} />

              <button
                type='button'
                onClick={() => doUnselectUser(user)}
                className='remove-button'
              >
                <CloseCircleOutline />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className='list-controls'>
        <Button
          type='button'
          className='medium-button'
          onClick={() => setIsModalOpen(true)}
        >
          Add User
        </Button>
      </div>

      <Modal
        title='Select inspector'
        header={
          <div className='search-container'>
            <SearchInput
              value={userListParams.search}
              onChange={(v) => setUserListParams({ ...userListParams, search: v?.target?.value ?? '' })}
              placeholder='Search by name, location or ID...'
              data-testid='InspectorsModal.search'
            />
          </div>
        }
        actions={
          <Pagination
            setPage={(v) => setUserListParams({ ...userListParams, page: v })}
            page={userListParams.page}
            pageSize={10}
            count={userCount}
            threshold={3}
          />
        }
        className={'users-modal inspectors-modal'}
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={doCloseModal}
      >
        <Table
          data={userList.map((u) => ({
            ...u,
            selected: selectedUsers?.length ? !!selectedUsers?.some(uSel => uSel?.id === u?.id) : false
          }))}
          columns={inspectorsTableColumns}
          onRowClick={(u) => doSelectUser(u)}
          sortOrder={userListParams.sortOrder}
          setSortBy={(sortBy, sortOrder) => setUserListParams({ ...userListParams, sortBy, sortOrder })}
          sortBy={userListParams.sortBy}
        />
      </Modal>
    </div >
  );
};
export default UserSelectorBlock;
