import request from 'lib/request';
import makeLogger from 'lib/makeLogger';
import { workObjectStateToPOSTParams } from './dataTransform';
import { processAPIError } from './workObjectsFormValidator';

const log = makeLogger('workObjectsService');

const defaultWorkflowsListParams = {
  select: [
    'name',
    'description',
    'defaultSource',
    'defaultDestination',
    'sourceLocation',
    'destinationLocation',
  ],
};

const defaultWorkflowsDetailsParams = {
  select: ['name', 'description'],
  relations: ['workflowSteps'],
};

const defaultSourcesParams = {
  select: ['externalId', 'name'],
  relations: ['location', 'type'],
};
const defaultAssetsParams = {
  select: ['name', 'showId', 'externalId'],
  relations: ['images', 'type'],
};
const defaultWorkObjectDetailParams = {
  select: [
    'id',
    'status',
    'quantity',
    'deadline',
    'remainingQuantity',
    'userCanEdit',
    'externalId',
  ],
  relations: [
    'asset',
    'customFields',
    'source',
    'asset.status',
    'asset.images',
    'source.images',
    'partialWorkObjects',
    'workObjectSteps',
    'originalWorkflow',
    'activities',
    'vendor',
    'vendor.location',
  ],
};
const defaultWorkObjectListParams = {
  page: 1,
  pageSize: 10,
  select: [
    'id',
    'quantity',
    'defaultSource',
    'defaultDestination',
    'defaultSourceExternalId',
    'defaultDestinationExternalId',
    'sourceLocation',
    'destinationLocation',
    'progress',
    'hasMissingInspectionPlan',
    'lastActivity',
    'workflow',
    'workObject.externalId',
  ],
  relations: ['assetImg', 'sourceImg', 'asset', 'source', 'vendor'],
};

const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const exportWorkObjects = () => {
  return request
    .get('/work-objects/export')
    .then((res) => {
      log('Work Object export successfully fetched');
      return res;
    })
    .catch((err) => {
      log('Work Object export failed with error: ', err);
      return Promise.reject(err);
    });
};

const getWorkObjects = (params) => {
  log('Attempting to fetch work objects with params', params);

  return request
    .get('/work-objects', {
      params: queryParamsToGETParams({
        ...defaultWorkObjectListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Work objects successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the work objects for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getWorkObject = ({ id, ...params }) => {
  log('Attempting to fetch work object with params', params);

  return request
    .get(`/work-objects/${id}`, {
      params: queryParamsToGETParams({
        ...defaultWorkObjectDetailParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Work object successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the work object for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getWorkflows = (params) => {
  log('Attempting to fetch workflows with params', params);
  return request
    .get('/workflows', {
      params: queryParamsToGETParams({
        ...defaultWorkflowsListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Workflows successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching workflows!', params, err);
      return Promise.reject(err);
    });
};

const getWorkflow = ({ id, ...params }) => {
  log('Attempting to fetch workflow with params', { id, ...params });

  return request
    .get(`/workflows/${id}`, {
      params: {
        ...defaultWorkflowsDetailsParams,
        ...params,
      },
    })
    .then((response) => {
      log('Workflow successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the workflow for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getSources = (params) => {
  log('Attempting to fetch sources with params', params);
  return request
    .get('/sources', {
      params: queryParamsToGETParams({
        ...defaultSourcesParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Sources successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const defaultSourceCityFilterOption = {
  select: ['name'],
  filters: { atWOSource: true },
};

const getSourceCityFilterOptions = (params) => {
  return request
    .get('/source-cities', {
      params: queryParamsToGETParams({
        ...defaultSourceCityFilterOption,
        ...params,
      }),
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const getAssets = (params) => {
  log('Attempting to fetch assets with params', params);
  return request
    .get('/assets', {
      params: queryParamsToGETParams({
        ...defaultAssetsParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Assets successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching assets!', params, err);
      return Promise.reject(err);
    });
};

const createPartial = (partial) => {
  log('Attempting to save work object partial.');

  return request
    .post(`/work-objects/${partial.id}/create-partial`, {
      quantity: partial.quantity,
      deadline: partial.deadline,
    })
    .then((response) => {
      log('Work object partial successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in saving work object partial',
        e.response.data.message,
      );
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processAPIError(e.response.data.details, partial);
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};
const updatePartial = ({
  id,
  isReadyForInspection = undefined,
  deadline = undefined,
  quantity = undefined,
}) => {
  log('Attempting to update work object partial.');

  return request
    .put(`/work-objects/partial/${id}`, {
      isReadyForInspection,
      deadline,
      quantity,
    })
    .then((response) => {
      log('Work object partial successfully updated.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in updating work object partial',
        e.response.data.message,
      );
      if (e.response.data.errorCode === 'entity_body_001') {
        // validation error
        const error = processAPIError(e.response.data.details, {
          id,
          isReadyForInspection,
          deadline,
        });
        return Promise.reject({ payload: error });
      } else {
        return Promise.reject({
          payload: {
            errors: [
              'An error has occured while performing this operation. Please try again',
            ],
          },
        });
      }
    });
};
const performInspectionAction = (action, notes = '') => {
  log('Attempting to save work object inspection action.');

  return request
    .post(`/inspections/${action?.inspectionId}/take-decision/${action?.id}`, {
      notes,
    })
    .then((response) => {
      log('Work object inspection action successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in saving work object inspection action',
        e.response.data.message,
      );
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processAPIError(e.response.data.details, action);
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};
const acceptPartial = (partial) => {
  log('Attempting to accept work object.');
  return request
    .post(`/work-objects/partial/${partial.id}/accept`, {})
    .then((response) => {
      log('Work object partial successfully accepted.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in accepting work object partial',
        e.response.data.message,
      );
      if (e.response.data.errorCode === 'entity_body_001') {
        // validation error
        const error = processAPIError(e.response.data.details, partial);
        return Promise.reject({ payload: error });
      } else {
        return Promise.reject({
          payload: {
            errors: [
              'An error has occured while performing this operation. Please try again',
            ],
          },
        });
      }
    });
};
const rejectPartial = (partial) => {
  log('Attempting to reject work object.');
  return request
    .post(`/work-objects/partial/${partial.id}/reject`, {})
    .then((response) => {
      log('Work object partial successfully rejected.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in rejecting work object partial',
        e.response.data.message,
      );
      if (e.response.data.errorCode === 'entity_body_001') {
        // validation error
        const error = processAPIError(e.response.data.details, partial);
        return Promise.reject({ payload: error });
      } else {
        return Promise.reject({
          payload: {
            errors: [
              'An error has occured while performing this operation. Please try again',
            ],
          },
        });
      }
    });
};

const skipInspection = (id) => {
  log('Attempting to skip work object inspection.');
  return request
    .post(`/inspections/${id}/skip`)
    .then((response) => {
      log('Work object inspection successfully skipped.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in skiping work object inspection',
        e.response.data.message,
      );
      if (e.response.data.errorCode === 'entity_body_001') {
        // validation error
        const error = processAPIError(e.response.data.details, id);
        return Promise.reject({ payload: error });
      } else {
        return Promise.reject({
          payload: {
            errors: [
              'An error has occured while performing this operation. Please try again',
            ],
          },
        });
      }
    });
};

const publishInspection = (partialId, pairId) => {
  log(
    `Attempting to publish inspection pair ${pairId} for partial ${partialId}.`,
  );
  return request
    .post(`/work-objects/partials/${partialId}/pairs/${pairId}/publish`)
    .then((response) => {
      log('Work object inspection successfully published.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in publishing work object inspection',
        e.response.data.message,
      );
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};

const saveWorkObject = (workObject) => {
  log('Attempting to save work object.');

  const query = workObject.id
    ? `/work-objects/${workObject.id}`
    : '/work-objects';
  const method = workObject.id ? 'put' : 'post';

  return request[method](query, {
    ...workObjectStateToPOSTParams(workObject),
  })
    .then((response) => {
      log('Work objects successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in saving work object', e.response.data.message);
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processAPIError(e.response.data.details, workObject);
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveWorkObjectInspectionPair = async (partialId, pair) => {
  const method = pair.id ? 'put' : 'post';
  const url = pair.id
    ? `/work-objects/partials/${partialId}/pairs/${pair.id}`
    : `/work-objects/partials/${partialId}/pairs`;
  try {
    const response = await request[method](url, pair);
    log('Inspection pair successfully saved.', response);
    return response;
  } catch {
    return await Promise.reject({
      payload: {
        errors: [
          'An error has occured while performing this operation. Please try again',
        ],
      },
    });
  }
};

const workObjectService = {
  exportWorkObjects,
  getWorkObjects,
  getWorkObject,
  getWorkflows,
  getWorkflow,
  getSources,
  getSourceCityFilterOptions,
  getAssets,
  acceptPartial,
  createPartial,
  updatePartial,
  performInspectionAction,
  rejectPartial,
  skipInspection,
  publishInspection,
  saveWorkObject,
  saveWorkObjectInspectionPair,
};

export default workObjectService;
