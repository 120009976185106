import EditButton from 'lib/components/edit-button/EditButton';

import { getStatusLabel } from 'config/inspectionTypeStatus';
import { getTypeLabel } from 'config/inspectionTypeType';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { ADD_INSPECTION_TYPES_ROLES } from 'modules/inspection-types/inspectionTypesPermissions';
import useInspectionTypesDetails from 'modules/inspection-types/useInspectionTypesDetails';

import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import React from 'react';
import { useHistory } from 'react-router';
import './InspectionTypesDetailsPage.scss';
import UsageDecisionsDetailTable from './UsageDecisionsDetailTable';

const InspectionTypesDetailsPage = (props) => {
  const history = useHistory();
  const { state } = useInspectionTypesDetails(props);
  const hasUsageDecisions = !!state.inspectionType?.usageDecisions?.length;

  const passUsageDecisions = state.inspectionType?.usageDecisions?.filter(
    (d) => d.decisionType === 'pass',
  );
  const failUsageDecisions = state.inspectionType?.usageDecisions?.filter(
    (d) => d.decisionType === 'fail',
  );

  const usageDecisionGroups = state.inspectionType?.usageDecisions
    ?.filter((d) => d.decisionType === 'group')
    .reduce((acc, decision) => {
      if (!acc[decision.groupNumber]) {
        acc[decision.groupNumber] = [];
      }
      acc[decision.groupNumber].push(decision);
      return acc;
    }, []);

  const severeDefectFailDecision = failUsageDecisions?.find((ud) =>
    ud?.criteriaId === '10'
  ) ?? null;

  const severeDefectFailNotifyToUsers = severeDefectFailDecision?.notifyToUsers?.length ? severeDefectFailDecision.notifyToUsers : [];

  return (
    <div
      className='inspection-types-details-page container-m'
      data-testid='InspectionTypesDetailsPage.container'
    >
      <div className='row inspection-types-header'>
        <div className='details'>
          <h1>{state.inspectionType.name}</h1>
          <div className='header-info'>
            <p>ID: {state.inspectionType?.id}</p>
            <p>Status: {getStatusLabel(state.inspectionType?.status)}</p>
            <p>Target: {getTypeLabel(state.inspectionType?.type)}</p>
          </div>
        </div>
        <div className='actions'>
          <ProtectedComponent allowed={ADD_INSPECTION_TYPES_ROLES}>
            <EditButton
              data-testid='InspectionTypesDetailsPage.edit'
              onClick={() =>
                props.history.push(
                  `/inspection-types/${state.inspectionType.id}/edit`,
                )
              }
            >
              Edit
            </EditButton>
          </ProtectedComponent>
        </div>
      </div>
      <div
        className='content usage-decisions-grid'
        data-testid='InspectionTypesDetailsPage.decisionsContainer'
      >
        {!hasUsageDecisions ? (
          <p>
            Inspection type has no usage decisions, and will not be available
            inside workflows
          </p>
        ) : (
          <>
            {!!passUsageDecisions?.length && (
              <>
                <h5>Inspection pass usage decisions</h5>
                <UsageDecisionsDetailTable
                  data-testid='InspectionTypesDetailsPage.passDecisionsTable'
                  data={passUsageDecisions}
                />
              </>
            )}
            {!!failUsageDecisions?.length && (
              <>
                <h5>Inspection fail usage decisions</h5>
                {!!severeDefectFailNotifyToUsers?.length && (
                  <div className="severe-defect-fail-notify-users">
                    <p>Severe Defect Notify Users:</p>
                    <div className='users-list '>
                      {severeDefectFailNotifyToUsers.map((user) => (
                        <NamedAvatar
                          onClick={() => { history.push(`/users/${user.id}`) }}
                          user={user}
                          key={user.id}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <UsageDecisionsDetailTable
                  data-testid='InspectionTypesDetailsPage.failDecisionsTable'
                  data={failUsageDecisions}
                />
              </>
            )}
            {usageDecisionGroups.map((groupDecisions, groupNumber) => (
              <React.Fragment key={groupNumber}>
                <h5>Decision group {groupNumber + 1}</h5>
                <UsageDecisionsDetailTable
                  data-testid='InspectionTypesDetailsPage.groupDecisionsTable'
                  data-groupnumber={groupNumber}
                  data={groupDecisions}
                />
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default InspectionTypesDetailsPage;
