import { useEffect, useReducer, useState } from 'react';
import INSPECTION_TYPE_STATUS, {
  getStatusLabel,
} from 'config/inspectionTypeStatus';
import INSPECTION_TYPE_TYPE, { getTypeLabel } from 'config/inspectionTypeType';
import {
  reducer,
  initialState,
  INSPECTION_TYPES_FORM_ACTIONS,
} from './inspectionTypesFormReducer';
import inspectionTypesService from './inspectionTypesService';
import inspectionTypesFormValidator from './inspectionTypesFormValidator';
import {
  inspectionTypeToFormState,
  prepareForValidation,
} from './dataTransform';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const FORWARD_LABEL = 'Forward to Decision Group';

const defaultInspectionTypesDetailsParams = {
  select: ['name', 'status', 'type', 'isSurvey'],
  relations: ['usageDecisions'],
};

const statusOptions = [
  {
    value: INSPECTION_TYPE_STATUS.ACTIVE,
    label: getStatusLabel(INSPECTION_TYPE_STATUS.ACTIVE),
  },
  {
    value: INSPECTION_TYPE_STATUS.DISABLED,
    label: getStatusLabel(INSPECTION_TYPE_STATUS.DISABLED),
  },
];
const typeOptions = [
  {
    value: INSPECTION_TYPE_TYPE.ASSET,
    label: getTypeLabel(INSPECTION_TYPE_TYPE.ASSET),
  },
  {
    value: INSPECTION_TYPE_TYPE.SOURCE,
    label: getTypeLabel(INSPECTION_TYPE_TYPE.SOURCE),
  },
];

const useInspectionTypesForm = (props) => {
  const [notifyOptions, setNotifyOptions] = useState([]);
  const [criteriasOptions, setCriteriasOptions] = useState([]);
  const [actionsOptions, setActionsOptions] = useState([]);
  const [groupActionsOptions, setGroupActionsOptions] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useNavigationPrompt(state.isDirty);

  const getRoles = () => {
    return inspectionTypesService
      .getNotifiableRoles()
      .then((roles) =>
        setNotifyOptions(
          roles.data?.map((role) => ({ value: role.id, label: role.role })),
        ),
      )
      .catch(() => {
        dispatch({
          type: INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['There was an error in fetching the user roles.'],
          },
        });
      });
  };

  const getCriterias = () => {
    return inspectionTypesService
      .getUsageDecisionCriterias()
      .then((criterias) =>
        setCriteriasOptions(
          criterias.data.map((criteria) => ({
            value: criteria.id,
            label: criteria.name,
          })),
        ),
      )
      .catch(() => {
        dispatch({
          type: INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['There was an error in fetching the decision criterias.'],
          },
        });
      });
  };

  const getActions = () => {
    return inspectionTypesService
      .getUsageDecisionActions()
      .then((actions) => {
        dispatch({
          type: INSPECTION_TYPES_FORM_ACTIONS.APP_SETS_FORWARD_OPTION,
          payload: actions?.data.find((item) => item.name === FORWARD_LABEL),
        });
        const formattedOptions = actions?.data
          .filter((item) => item.name !== FORWARD_LABEL)
          .map((action) => ({
            value: action.id,
            label: action.name,
            forwardIndex: null,
          }));
        setGroupActionsOptions(formattedOptions);
      })
      .catch(() => {
        dispatch({
          type: INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['There was an error in fetching the decision actions.'],
          },
        });
      });
  };

  const getInspectionType = () => {
    const id = props.match?.params?.id;
    return id
      ? inspectionTypesService
        .getInspectionType({ id, ...defaultInspectionTypesDetailsParams })
        .then((inspection) => {
          dispatch({
            type: INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE,
            payload: inspectionTypeToFormState(inspection),
          });
        })
        .catch((e) => {
          dispatch({
            type: INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
      : Promise.resolve();
  };

  useEffect(() => {
    getInspectionType().then(() => {
      getActions();
      getCriterias();
      getRoles();
    });

    //cleanup state after unmounting
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.INITIAL_STATE,
    });
  }, []);

  useEffect(() => {
    if (state.forwardOption?.id) {
      const groupForwardActions = state.decisionGroups.map((group, index) => ({
        label: `${state.forwardOption.name} ${index + 1}`,
        value: `${state.forwardOption.id}-${index}`,
        forwardIndex: index,
      }));
      setActionsOptions([...groupActionsOptions, ...groupForwardActions]);
    }
  }, [state.decisionGroups, state.forwardOption?.id, groupActionsOptions]);

  const userTypesName = (e) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_NAME,
      payload: e.target.value || '',
    });
  };

  const userSetsStatus = (status) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_STATUS,
      payload: status,
    });
  };

  const userSetsType = (type) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_TYPE,
      payload: type,
    });
  };

  const userSetsIsSurvey = (v) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_IS_SURVEY,
      payload: v,
    });
  };

  const userSetsAutomaticPass = (e) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_AUTOMATIC_DECISION,
      payload: {
        value: e.target.checked,
        key: 'automaticPass',
        arrayKey: 'passDecisions',
      },
    });
  };
  const userSetsAutomaticFail = (e) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_AUTOMATIC_DECISION,
      payload: {
        value: e.target.checked,
        key: 'automaticFail',
        arrayKey: 'failDecisions',
      },
    });
  };

  const userTogglesSevereDefectFailDecision = (e) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_TOGGLES_SEVERE_DEFECT_DECISION,
      payload: !!e.target.checked,
    });
  };

  const userSetsSevereDefectFailNotifyUsers = (notifyUsers) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_SEVERE_DEFECT_DECISION_NOTIFY_USERS,
      payload: notifyUsers,
    });
  };

  const userTypesPassDecisionName = (e, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_DECISION_ITEM_NAME,
      payload: { index, value: e.target.value || '', key: 'passDecisions' },
    });
  };
  const userTypesFailDecisionName = (e, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_DECISION_ITEM_NAME,
      payload: { index, value: e.target.value || '', key: 'failDecisions' },
    });
  };
  const userTypesGroupDecisionName = (e, groupIndex, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_GROUP_DECISION_ITEM_NAME,
      payload: {
        index,
        value: e.target.value || '',
        arrayKey: 'decisionGroups',
        groupIndex,
      },
    });
  };

  const userSetsPassDecisionCriteria = (criteria, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_CRITERIA,
      payload: { index, criteria, arrayKey: 'passDecisions' },
    });
  };
  const userSetsFailDecisionCriteria = (criteria, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_CRITERIA,
      payload: { index, criteria, arrayKey: 'failDecisions' },
    });
  };
  const userSetsGroupDecisionCriteria = (
    criteria,
    groupIndex,
    decisionIndex,
  ) => {
    return dispatch({
      type:
        INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_GROUP_DECISION_ITEM_CRITERIA,
      payload: {
        groupIndex,
        decisionIndex,
        criteria,
        arrayKey: 'decisionGroups',
      },
    });
  };
  const userSetsPassDecisionAction = (action, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_ACTION,
      payload: { index, action, arrayKey: 'passDecisions' },
    });
  };
  const userSetsFailDecisionAction = (action, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_ACTION,
      payload: { index, action, arrayKey: 'failDecisions' },
    });
  };
  const userSetsGroupDecisionAction = (action, groupIndex, decisionIndex) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_GROUP_DECISION_ITEM_ACTION,
      payload: {
        groupIndex,
        decisionIndex,
        action,
        arrayKey: 'decisionGroups',
      },
    });
  };

  const userSetsPassDecisionNotify = (notifyTo, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_NOTIFY,
      payload: { index, notifyTo, arrayKey: 'passDecisions' },
    });
  };

  const userSetsFailDecisionNotify = (notifyTo, index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_NOTIFY,
      payload: { index, notifyTo, arrayKey: 'failDecisions' },
    });
  };
  const userSetsGroupDecisionNotify = (notifyTo, groupIndex, decisionIndex) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_GROUP_DECISION_ITEM_NOTIFY,
      payload: {
        groupIndex,
        decisionIndex,
        notifyTo,
        arrayKey: 'decisionGroups',
      },
    });
  };

  const userAddsPassDecisionItem = () => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_DECISION_ITEM,
      payload: 'passDecisions',
    });
  };
  const userAddsFailDecisionItem = () => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_DECISION_ITEM,
      payload: 'failDecisions',
    });
  };
  const userAddsGroupDecisionItem = (groupIndex) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_GROUP_DECISION_ITEM,
      payload: { arrayKey: 'decisionGroups', groupIndex },
    });
  };

  const userRemovesPassDecisionItem = (index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_DECISION_ITEM,
      payload: { index, arrayKey: 'passDecisions' },
    });
  };
  const userRemovesFailDecisionItem = (index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_DECISION_ITEM,
      payload: { index, arrayKey: 'failDecisions' },
    });
  };
  const userRemovesGroupDecisionItem = (groupIndex, decisionIndex) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_GROUP_DECISION_ITEM,
      payload: { groupIndex, decisionIndex, arrayKey: 'decisionGroups' },
    });
  };
  const userAddsDecisionGroup = () => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_DECISION_GROUP,
    });
  };
  const userRemovesDecisionGroup = (index) => {
    return dispatch({
      type: INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_DECISION_GROUP,
      payload: { index, arrayKey: 'decisionGroups' },
    });
  };

  const userSubmitsForm = (e) => {
    e.preventDefault();
    return dispatch({ type: INSPECTION_TYPES_FORM_ACTIONS.USER_SUBMITS_FORM });
  };

  const hasLinkedGroups =
    state.passDecisions.some(
      (decision) =>
        decision?.action?.value?.forwardIndex !== null &&
        decision?.action?.value?.forwardIndex !== undefined,
    ) ||
    state.failDecisions.some(
      (decision) =>
        decision?.action?.value?.forwardIndex !== null &&
        decision?.action?.value?.forwardIndex !== undefined,
    ) ||
    state.decisionGroups
      .flatMap((group) => group)
      .some(
        (decision) =>
          decision?.action?.value?.forwardIndex !== null &&
          decision?.action?.value?.forwardIndex !== undefined,
      );

  const getGroupForwardOptions = (groupIndex) => {
    return actionsOptions.filter(
      (action) =>
        action?.forwardIndex === null || action?.forwardIndex !== groupIndex,
    );
  };

  useEffect(() => {
    if (!state.loading) {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.loading]);

  useEffect(() => {
    if (state.loading) {
      inspectionTypesFormValidator(prepareForValidation(state))
        .then((data) =>
          inspectionTypesService
            .saveInspectionType(data)
            .then((response) =>
              props.history.push(`/inspection-types/${response.id}`),
            ),
        )
        .catch((e) => {
          dispatch({
            type: INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type: INSPECTION_TYPES_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          }),
        );
    }
  }, [state.loading]);

  return {
    state,
    getRoles,
    getCriterias,
    getActions,
    notifyOptions,
    criteriasOptions,
    actionsOptions,
    groupActionsOptions,
    statusOptions,
    typeOptions,
    userTypesName,
    userSetsStatus,
    userSetsType,
    userSetsIsSurvey,
    userSetsAutomaticPass,
    userSetsAutomaticFail,
    userTogglesSevereDefectFailDecision,
    userSetsSevereDefectFailNotifyUsers,
    userTypesPassDecisionName,
    userTypesFailDecisionName,
    userTypesGroupDecisionName,
    userSetsPassDecisionCriteria,
    userSetsFailDecisionCriteria,
    userSetsGroupDecisionCriteria,
    userSetsPassDecisionAction,
    userSetsFailDecisionAction,
    userSetsGroupDecisionAction,
    userSetsPassDecisionNotify,
    userSetsFailDecisionNotify,
    userSetsGroupDecisionNotify,
    userAddsPassDecisionItem,
    userAddsFailDecisionItem,
    userAddsGroupDecisionItem,
    userRemovesPassDecisionItem,
    userRemovesFailDecisionItem,
    userRemovesGroupDecisionItem,
    userAddsDecisionGroup,
    userRemovesDecisionGroup,
    userSubmitsForm,
    hasLinkedGroups,
    getGroupForwardOptions,
  };
};
export default useInspectionTypesForm;
