import * as yup from 'yup';
import _set from 'lodash.set';
import _get from 'lodash.get';

import validator from 'lib/validator';

// validate if decision name value is unique in array
yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (item) => _get(item, path)?.toLowerCase();
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const fieldIndex = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `${this.path}[${fieldIndex}].${path}`,
      message,
    });
  });
});

yup.addMethod(yup.array, 'validDecision', function (message, arrayKey) {
  return this.test('match', message, function (list) {
    const arrayToCompare =
      arrayKey === 'passDecisions' ? 'failDecisions' : 'passDecisions';
    if (
      (this.parent[arrayToCompare]?.length > 0 && list.length > 0) ||
      (this.parent[arrayToCompare]?.length === 0 && list.length === 0)
    ) {
      return true;
    }
    return this.createError({
      path:
        list.length > 0
          ? `decisionErrors.${arrayKey}`
          : `decisionErrors.${arrayToCompare}`,
      message,
    });
  });
});

const decisionsSchema = yup.array().of(
  yup.object().shape({
    name: yup.object().shape({
      value: yup
        .string()
        .required('Decision name is a required field')
        .max(50, 'Decision name is too long'),
    }),
    action: yup.object().shape({
      value: yup
        .object()
        .nullable()
        .required('Action is a required field')
        .shape({
          value: yup.string().required('Action is a required field'),
        }),
    }),
    criteria: yup.object().shape({
      value: yup.object().shape({
        value: yup.string().required('Criteria is a required field'),
      }),
    }),
    notifyTo: yup
      .object()
      .shape({
        value: yup.array()
      }),
    notifyToUsers: yup
      .object()
      .shape({
        value: yup.array()
      }),
  }).test(
    'notifyTo-or-notifyToUsers',
    'Notify is mandatory',
    function (v, ctx) {
      const errors = [];

      if (v?.criteria?.value?.value === '10') {
        if (!(v?.notifyTo?.value?.length || v?.notifyToUsers?.value?.length)) {
          errors.push(
            ctx.createError({
              path: `${ctx.path}.notifyTo.value`,
              message: 'User or Role notification required',
              params: {
                originalValue: v?.notifyTo?.value,
              },
            }),
            ctx.createError({
              path: `${ctx.path}.notifyToUsers.value`,
              message: 'User or Role notification required',
              params: {
                originalValue: v?.notifyToUsers?.value,
              },
            })
          );
        }

        const actionIdMatch = /^(\d+).*/.exec(v?.action?.value?.value);

        if (actionIdMatch?.length >= 2 && actionIdMatch[1] !== '11') {
          errors.push(
            ctx.createError({
              path: `${ctx.path}.action.value`,
              message: 'Only Forward allowed',
              params: {
                originalValue: v?.action?.value,
              },
            })
          );
        }
      } else {
        if (!v?.notifyTo?.value?.length) {
          errors.push(
            ctx.createError({
              path: `${ctx.path}.notifyTo.value`,
              message: 'Notify is mandatory',
              params: {
                originalValue: v?.notifyTo?.value,
              },
            })
          );
        }
      }

      return errors?.length ? new yup.ValidationError(errors) : true;
    }
  ),
);

export const inspectionTypesFormValidationSchema = yup.object().shape({
  name: yup.object().shape({
    value: yup
      .string()
      .required('Name is a required field')
      .max(50, 'Name is too long'),
  }),
  status: yup.object().shape({
    value: yup.string().required('Status is a required field'),
  }),
  failDecisions: decisionsSchema
    .unique('Decision name needs to be unique', 'name.value')
    .validDecision(
      'Inspection type must have pass and fail decisions or none of them',
      'failDecisions',
    ),
  passDecisions: decisionsSchema
    .unique('Decision name needs to be unique', 'name.value')
    .validDecision(
      'Inspection type must have pass and fail decisions or none of them',
      'passDecisions',
    ),
  decisionGroups: yup
    .array()
    .of(
      decisionsSchema.unique('Decision name needs to be unique', 'name.value'),
    ),
});

export const processInspectionTypesFormError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    const arrayPath = curr.path.split('.');
    const path =
      curr.path.includes('passDecisions') ||
        curr.path.includes('failDecisions') ||
        curr.path.includes('decisionGroups')
        ? `${arrayPath[0]}.${arrayPath[1]}`
        : arrayPath[0];
    const isSimpleValue =
      path.includes('notifyTo') ||
      path.includes('name') ||
      path.includes('status');
    const defaultKey = isSimpleValue ? 'value' : 'value.value';
    const defaultValue = path.endsWith('notifyTo') ? [] : '';
    let originalValue, valueIndex;

    if (path.includes('name') && Array.isArray(curr.params.originalValue)) {
      valueIndex = Number(curr.path.match(/\[(.*?)\]/)[1]);
      originalValue = curr.params.originalValue[valueIndex].name.value;
    } else {
      originalValue = curr.params.originalValue;
    }

    _set(acc, path, {
      [defaultKey]: originalValue || defaultValue,
      errors: curr.errors,
    });

    return acc;
  }, input);
};

export const processAPIError = (errorDetails, user) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => path);
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, user);
};

const inspectionTypesFormValidator = (state) =>
  validator(
    state,
    inspectionTypesFormValidationSchema,
    processInspectionTypesFormError,
  );

export default inspectionTypesFormValidator;
