import { groupBy, cloneDeep } from 'lodash';

import { normalizeWhitespace } from 'lib/dataTransform';

import { MAX_FIELD_CHARS } from './inspectionTypesFormReducer';

const TYPE = {
  FAIL: 'fail',
  PASS: 'pass',
  GROUP: 'group',
};

const decisionsToPOSTParams = (decisions, type) =>
  decisions.map((item) => ({
    name: item.name.value,
    action: { id: item.action.value.value.split('-')[0] },
    criteria: { id: item.criteria.value.value },
    notifyTo: item.notifyTo.value.map((role) => ({ id: role.value })),
    notifyToUsers: item?.notifyToUsers?.value?.length
      ? item.notifyToUsers.value?.map(user => ({ id: user.id }))
      : [],
    decisionType: type,
    forwardActionToGroup:
      item.action?.value?.forwardIndex >= 0
        ? item.action?.value?.forwardIndex
        : undefined,
  }));

const decisionGroupsToPOSTParams = (groups) =>
  groups.flatMap((group, groupIndex) =>
    group.flatMap((decision) => ({
      name: decision.name.value,
      action: { id: decision.action.value.value.split('-')[0] },
      criteria: { id: decision.criteria.value.value },
      notifyTo: decision.notifyTo.value.map((role) => ({ id: role.value })),
      decisionType: TYPE.GROUP,
      groupNumber: groupIndex,
      forwardActionToGroup:
        decision.action?.value?.forwardIndex >= 0
          ? decision.action?.value?.forwardIndex
          : undefined,
    })),
  );

export const inspectionTypeStateToPOSTParams = (state) => {
  const failDecisions = decisionsToPOSTParams(state.failDecisions, TYPE.FAIL);
  const passDecisions = decisionsToPOSTParams(state.passDecisions, TYPE.PASS);
  const decisionGroups = decisionGroupsToPOSTParams(state.decisionGroups);
  return {
    name: state.name.value,
    status: state.status.value,
    type: state.type.value,
    isSurvey: !!state.isSurvey.value,
    usageDecisions: [...failDecisions, ...passDecisions, ...decisionGroups],
  };
};

export const setValue = (key, data) => {
  const [responseKey, formKey] = Array.isArray(key)
    ? [key[0], key[1]]
    : [key, key];

  return {
    value: data[responseKey] || '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - (data[responseKey] || '').length,
  };
};

const decisionsToFormState = (decisions) => {
  return (decisions || []).map((decision) => ({
    name: { value: decision?.name || '', errors: [] },
    action: {
      value: {
        value:
          `${decision?.action?.id}${decision?.forwardActionToGroup !== null
            ? `-${decision?.forwardActionToGroup}`
            : ''
          }` || '',
        label:
          `${decision?.action?.name} ${decision?.forwardActionToGroup !== null
            ? decision?.forwardActionToGroup + 1
            : ''
          }` || '',
        forwardIndex: decision?.forwardActionToGroup,
      },
      errors: [],
    },
    criteria: {
      value: {
        value: decision?.criteria?.id || '',
        label: decision?.criteria?.name || '',
      },
      errors: [],
    },
    notifyTo: {
      value: (decision?.notifyTo || []).map((role) => ({
        value: role?.id || '',
        label: role?.role || '',
      })),
      errors: [],
    },
    notifyToUsers: {
      value: decision?.notifyToUsers,
      errors: [],
    },
  }));
};

const groupsToFormState = (decisions) => {
  const groupObject = groupBy(decisions, 'groupNumber');
  return Object.keys(groupObject).map((groupIndex) => [
    ...decisionsToFormState(groupObject[groupIndex]),
  ]);
};

export const inspectionTypeToFormState = (response) => {
  const failDecisions = (response?.usageDecisions || []).filter(
    (decision) => decision?.decisionType === TYPE.FAIL,
  );
  const passDecisions = (response?.usageDecisions || []).filter(
    (decision) => decision?.decisionType === TYPE.PASS,
  );
  const decisionGroups = (response?.usageDecisions || []).filter(
    (decision) => decision?.decisionType === TYPE.GROUP,
  );

  return {
    name: setValue('name', response),
    status: {
      value: response?.status || '',
      errors: [],
    },
    type: {
      value: response?.type || '',
      errors: [],
    },
    isSurvey: { value: !!response?.isSurvey, errors: [] },
    automaticPass: { value: response?.automaticPass, errors: [] },
    automaticFail: { value: response?.automaticFail, errors: [] },
    isLinkedToWorkflow: response.isLinkedToWorkflow ?? false,
    failDecisions: decisionsToFormState(failDecisions),
    passDecisions: decisionsToFormState(passDecisions),
    decisionGroups: groupsToFormState(decisionGroups),
    id: response.id,
  };
};

export const prepareForValidation = (state) => {
  const stateClone = cloneDeep(state);
  return {
    ...stateClone,
    name: {
      ...stateClone.name,
      value: normalizeWhitespace(stateClone.name.value),
    },
    failDecisions: (stateClone.failDecisions || []).map((decision) => ({
      ...decision,
      name: {
        ...decision.name,
        value: normalizeWhitespace(decision?.name?.value),
      },
    })),
    passDecisions: (stateClone.passDecisions || []).map((decision) => ({
      ...decision,
      name: {
        ...decision.name,
        value: normalizeWhitespace(decision?.name?.value),
      },
    })),
    decisionGroups: (stateClone.decisionGroups || []).map((decisionGroup) =>
      (decisionGroup || []).map((decision) => ({
        ...decision,
        name: {
          ...decision.name,
          value: normalizeWhitespace(decision?.name?.value),
        },
      })),
    ),
  };
};
